import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll'; // Use react-scroll for smooth scrolling
import './App.css';
import VehicleSection from './vehicle';
import Navbar from './navbar';
import GetInTouch from './contact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook,faWhatsapp, faInstagram,  } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';



const App = () => {
  const [navbarBg, setNavbarBg] = useState('bg-transparent');
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Menu toggle state

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setNavbarBg('bg-black bg-opacity-90 shadow-lg'); // Change navbar background to black on scroll
      } else {
        setNavbarBg('bg-transparent');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const counters = document.querySelectorAll('.animate-count');
    const speed = 50; // Adjust this for the count speed

    const countUp = (el) => {
      const target = +el.getAttribute('data-count');
      const updateCount = () => {
        const count = +el.innerText;
        const increment = target / speed;
        if (count < target) {
          el.innerText = Math.ceil(count + increment);
          setTimeout(updateCount, 20);
        } else {
          el.innerText = target;
        }
      };
      updateCount();
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            countUp(entry.target);
            observer.unobserve(entry.target); // Stop observing once counted
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the element is in view
    );

    counters.forEach((counter) => observer.observe(counter));
  }, []);

  return (
    <div className="App">
      {/* Navbar */}
      <Navbar />
       

      {/* Hero Section */}
      <header
        id="home"
        className="relative bg-cover bg-center pt-20 h-[50vh] flex items-center justify-center text-center"
        style={{ backgroundImage: "url('/header.jpg')" }}
      >
        <div className="container mx-auto text-white">
          <h1 className="text-5xl font-bold mb-4">Maa Tour And Travels</h1>
          <p className="text-lg mb-6">Experience Ranchi's Best Car Rental Service</p>
          <Link 
            to="footer" 
            smooth={true} 
            duration={500} 
            className="bg-red-600 text-white py-3 cursor-pointer px-6 rounded-lg hover:bg-red-700 transition duration-300"
          >
            Book Now
          </Link>
        </div>
      </header>

      {/* Why Choose Us Section */}
      <section className="py-16 bg-black text-white">
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold underline text-red-500 text-center mb-12">Why Choose Us?</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="p-6 text-center border-2 border-red-500 rounded-lg">
              <img src="/rplus.png" className="mx-auto mb-4" alt="Well Maintained & Clean Cars" />
              <h3 className="text-xl font-bold">WELL MAINTAINED & CLEAN CARS</h3>
              <p>Wide range of well-maintained & clean cars.</p>
            </div>
            <div className="p-6 text-center border-2 border-red-500 rounded-lg">
              <img src="/rplus.png" className="mx-auto mb-4" alt="Highly Trained Drivers" />
              <h3 className="text-xl font-bold">HIGHLY TRAINED DRIVERS</h3>
              <p>Drivers are verified & well-trained for driving on highways & hills.</p>
            </div>
            <div className="p-6 text-center border-2 border-red-500 rounded-lg">
              <img src="/rplus.png" className="mx-auto mb-4" alt="Client Satisfaction" />
              <h3 className="text-xl font-bold">CLIENT SATISFACTION</h3>
              <p>Provide the best routes, price, and services for 100% client satisfaction.</p>
            </div>
            <div className="p-6 text-center border-2 border-red-500 rounded-lg">
              <img src="/rplus.png" className="mx-auto mb-4" alt="Privacy & Freedom" />
              <h3 className="text-xl font-bold">PRIVACY & FREEDOM</h3>
              <p>Enjoy the privacy and freedom of having your own car.</p>
            </div>
          </div>
        </div>
      </section>

      

      {/* Services Section */}
      <section id="services" className="bg-black py-12">
        <div className="container mx-auto">
          <h2 className="text-4xl text-center underline font-bold text-red-500 mb-10">Our Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
             {/* Round Trip Cabs */}
             <div className="p-6 border-red-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/rt.png"
                alt="Round Trip Cabs"
                className="w-full h-full object-cover mb-4"
              />
              <h3 className="text-xl font-semibold text-white mb-2">Round Trip Cabs</h3>
              <p className="text-gray-300">
                Maa Tour and Travel offers seamless roundtrip cab services, ensuring convenient journeys from starting point to destination and back.
              </p>
            </div>

            {/* Oneway Drops */}
            <div className="p-6 border-red-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/one.png"
                alt="Oneway Drops"
                className="w-full h-full object-cover mb-4"
              />
              <h3 className="text-xl font-semibold text-white mb-2">Oneway Drops</h3>
              <p className="text-gray-300">
                Hassle-free one-way drop service provided by Maa Tour and Travel.
              </p>
            </div>

            {/* Local Rental */}
            <div className="p-6 border-red-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/local.png"
                alt="Local Rental"
                className="w-full h-full object-cover mb-4"
              />
              <h3 className="text-xl font-semibold text-white mb-2">Local Rental</h3>
              <p className="text-gray-300">
                Explore the city with ease using our local rental services.
              </p>
            </div>

            {/* Airport Transfers */}
            <div className="p-6 border-red-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/airport1.jpg"
                alt="Airport Transfers"
                className="w-full h-full object-cover mb-4"
              />
              <h3 className="text-xl font-semibold text-white mb-2">Airport Transfers</h3>
              <p className="text-gray-300">
                Make airport transfers stress-free with our reliable services.
              </p>
            </div>
          </div>
        </div>
      </section>

      <VehicleSection />

      <section className="bg-black text-white py-16">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold underline text-red-500 mb-12">Our Achievements</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="p-6 text-center">
              <h3 className="text-5xl font-bold text-red-500 animate-count" data-count="16000">0</h3>
              <p className="mt-4 text-lg">Satisfied Customers</p>
            </div>
            <div className="p-6 text-center">
              <h3 className="text-5xl font-bold text-red-500 animate-count" data-count="100">0</h3>
              <p className="mt-4 text-lg">Trained Drivers</p>
            </div>
            <div className="p-6 text-center">
              <h3 className="text-5xl font-bold text-red-500 animate-count" data-count="200">0</h3>
              <p className="mt-4 text-lg">Team Members</p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Form Section */}
<GetInTouch />

      {/* Footer */}
      <footer id="footer" className="bg-black text-white py-8">
  <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-4">
    <div className="text-center md:text-left mb-4 md:mb-0">
      <h2 className="text-lg underline font-semibold">Maa Tour And Travels</h2>
      <h3 className="text-sm">Providing diverse transportation solutions since 2009.</h3>
      <p>Maa Tour and Travels in Ranchi provides diverse transportation solutions in Ranchi, Jharkhand.</p>
    </div>
    
    <div className="text-center md:text-left">
      <p className="text-lg underline font-semibold">Contact Us</p>
      <p className="mt-2">Nawa Toli, Kamla Kant Ln, near Pahadi Mandir, Ranchi, Jharkhand 834001</p>
      { /* <p>Email: xyz@gmail.com</p> */}
      <div className="flex  space-x-2 mb-4">
          {/* Phone Icon */}
          <a
            href="tel:+919771707070"
            className="text-white hover:text-red-500 flex items-center space-x-2"
            aria-label="Call us"
          >
            <FontAwesomeIcon icon={faPhone} size="lg" />
            <span className="text-gray-300">+91 97717 07070</span>
          </a>
        </div>
      
      {/* Social Media Icons Section */}
      <div className="flex justify-center md:justify-start space-x-4 py-4 mt-4 md:mt-0">
      <a 
        href="https://wa.me/9771707070" 
        className="text-white hover:text-red-500 flex items-center space-x-2"
        target="_blank" 
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faWhatsapp} size="xl" />
        
      </a>
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="text-white hover:text-red-500 transition-colors duration-300">
          <FontAwesomeIcon icon={faFacebook} size="lg" />
        </a>
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="text-white hover:text-red-500 transition-colors duration-300">
          <FontAwesomeIcon icon={faInstagram} size="lg" />
        </a>
       
      </div>
    </div>
  </div>
</footer>
    </div>
  );
}

export default App;



