import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-scroll';

const VehicleSection = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 cards at a time
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-black py-24">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl underline font-bold text-red-500 mb-8">Range of Vehicles</h2>
        <p className="text-gray-300 text-2xl mb-12">
          We have a wide range of well-maintained vehicles with experienced drivers.
        </p>
        <Slider {...settings}>
          {/* Vehicle Card 1 */}
          <div className="p-6">
            <div className="border border-red-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/tempo.jpeg" // replace with actual image path
                alt="Tempo Traveller 16+1"
                className="w-full h-full object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">Tempo Traveller 16+1</h3>
              <p className="text-gray-300">Rs. 30/km & 250 km/day</p>
              <Link className="bg-red-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-red-500 text-white p-2">
                <span>16+1</span>
                <span>16 Seats</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          {/* Vehicle Card 2 */}
          <div className="p-6">
            <div className="border border-red-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/ertiga.jpeg" // replace with actual image path
                alt="Ertiga (6+1)"
                className="w-full h-full object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">Ertiga (6+1)</h3>
              <p className="text-gray-300">Rs. 15/km & 250 km/day</p>
              <Link className="bg-red-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-red-500 text-white p-2">
                <span>6+1</span>
                <span>6 Seats</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          {/* Vehicle Card 3 */}
          <div className="p-6">
            <div className="border border-red-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/tempo.jpeg" // replace with actual image path
                alt="Tempo Traveller 20+1"
                className="w-full h-full object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">Tempo Traveller 20+1</h3>
              <p className="text-gray-300">Rs. 32/km & 250 km/day</p>
              <Link className="bg-red-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-red-500 text-white p-2">
                <span>20+1</span>
                <span>20 Seats</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          {/* Vehicle Card 4 */}
          <div className="p-6">
            <div className="border border-red-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/innova.png" // replace with actual image path
                alt="Innova (6+1)"
                className="w-full h-full object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">Innova (6+1)</h3>
              <p className="text-gray-300">Rs. 15/km & 250 km/day</p>
              <Link className="bg-red-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-red-500 text-white p-2">
                <span>6+1</span>
                <span>6 Seats</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          {/* Vehicle Card 5 */}
          <div className="p-6">
            <div className="border border-red-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/dzire.jpeg" // replace with actual image path
                alt="DZire (4+1)"
                className="w-full h-full object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">DZire (4+1)</h3>
              <p className="text-gray-300">Rs. 15/km & 250 km/day</p>
              <Link className="bg-red-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-red-500 text-white p-2">
                <span>4+1</span>
                <span>4 Seats</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

          {/* Vehicle Card 6 */}
          <div className="p-6">
            <div className="border border-red-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <img
                src="/scorpio.jpeg" // replace with actual image path
                alt="Scorpio (6+1)"
                className="w-full h-full object-cover mb-4 rounded-t-lg transition-transform duration-300 hover:scale-105"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">Scorpio (6+1)</h3>
              <p className="text-gray-300">Rs. 15/km & 250 km/day</p>
              <Link className="bg-red-500 text-white font-semibold px-4 py-2 mt-4 inline-block" to="footer" smooth={true} duration={500}>
                Book Now
              </Link>
              <div className="flex justify-between mt-4 bg-red-500 text-white p-2">
                <span>6+1</span>
                <span>6 Seats</span>
                <span>Yes</span>
              </div>
            </div>
          </div>

        </Slider>
      </div>
    </section>
  );
};

// Custom Next Arrow Component
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} right-4 z-10`}
      style={{ ...style, display: 'block', background: '#000', borderRadius: '50%' }}
      onClick={onClick}
    />
  );
}

// Custom Prev Arrow Component
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} left-4 z-10`}
      style={{ ...style, display: 'block', background: '#000', borderRadius: '50%' }}
      onClick={onClick}
    />
  );
}

export default VehicleSection;
