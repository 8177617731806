import React, { useState } from "react";

const GetInTouch = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch("https://maatourtravels.com/mail.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Your details have been sent successfully!");
        setFormData({ name: "", phone: "" });
      } else {
        alert("Failed to send details. Please try again.");
      }
    } catch (error) {
      console.error("Error sending data:", error);
      alert("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="py-16 bg-black">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold text-red-500 mb-8">Get in Touch</h2>
        <form
          onSubmit={handleSubmit}
          className="max-w-md mx-auto border-2 border-red-500 bg-gray-800 p-6 shadow-lg rounded-lg"
        >
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-left text-white font-semibold mb-2"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
              placeholder="Your Name"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="phone"
              className="block text-left text-white font-semibold mb-2"
            >
              Phone Number
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
              placeholder="Your Phone Number"
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className={`bg-red-600 text-white px-6 py-2 rounded-lg hover:bg-red-700 transition duration-300 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {loading ? "Sending..." : "Get a Call"}
          </button>
        </form>
      </div>
    </section>
  );
};

export default GetInTouch;
